import { formatDate } from '@/functions/formatDate'
import React from 'react'

type ArticleWrapper = {
    children: React.ReactNode
}

export const ArticleWrapper: React.FC<ArticleWrapper> = ({ children }) => {
    return <article className="p-article">{children}</article>
}

type ArticleTitle = {
    children: string
    badge?: {
        text: string
        color: 'crimson' | 'red'
    }
}

export const ArticleTitle: React.FC<ArticleTitle> = ({ children, badge }) => {
    return (
        <h2 className="p-article__title">
            {children}
            {badge && (
                <div
                    style={{
                        background: badge.color,
                        color: 'white',
                        borderRadius: '5px',
                        fontSize: '0.6em',
                        padding: '5px',
                        marginLeft: '20px',
                        display: 'inline-block',
                    }}
                >
                    {badge.text}
                </div>
            )}
        </h2>
    )
}

type ArticleContent = {
    children: React.ReactNode
    releaseDate: Date
    Header?: React.ReactElement
    Footer?: React.ReactElement
}

export const ArticleContent: React.FC<ArticleContent> = ({
    children,
    releaseDate,
    Header,
    Footer,
}) => {
    return (
        <div className="p-article__content">
            {Header}
            <div className="p-confirm">
                {children}
                <div className="p-article__date">
                    <span>更新日:</span>
                    <time dateTime={formatDate(releaseDate, '-')}>
                        {formatDate(releaseDate, '/')}
                    </time>
                </div>
            </div>
            {Footer}
        </div>
    )
}

type ArticleSection = {
    children: React.ReactNode
    title?: string
}

export const ArticleSection: React.FC<ArticleSection> = ({
    children,
    title,
}) => {
    return (
        <section className="p-confirm__section">
            {title && <h3 className="p-confirm__title">{title}</h3>}
            <div className="p-confirm__content">{children}</div>
        </section>
    )
}
