import * as React from "react"

import { GlobalLayout } from "@/components/GlobalLayout"
import { Head } from "@/components/Head"
import { SectionWrap } from "@/components/sections/SectionWrap"
import { ContentfulAsset, PageNewsQuery } from "@root/types/graphql-types"
import { ArticleRichTextContent } from "@/components/ArticleRichTextContent"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { useImage } from "@/hooks/useImage"
import {
    ArticleContent,
    ArticleSection,
    ArticleTitle,
    ArticleWrapper,
} from "@/components/Layouts/Article"
import { useExpandImg } from "@/hooks/useExpandImg"

interface Props {
    data: PageNewsQuery
}

export const NewsDetail: React.FC<Props> = ({ data }) => {
    const { openFromEvent, ExpandImg } = useExpandImg()
    const { getImageProps, Image } = useImage()

    return (
        <GlobalLayout
            Head={
                <Head
                    title={data.contentfulNews?.newsTitle || "NEWS記事"}
                    description={data.contentfulNews?.description || "NEWS記事"}
                />
            }
            CoverComponent={<ExpandImg />}
        >
            <SectionWrap
                addClass="--article"
                Title={
                    <TitleWithBgImg
                        title={"LIVE INFO"}
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <ArticleWrapper>
                    <ArticleTitle>
                        {data.contentfulNews?.newsTitle || ""}
                    </ArticleTitle>
                    <ArticleContent
                        releaseDate={new Date(data.contentfulNews?.updatedAt)}
                    >
                        <ArticleSection>
                            <ArticleRichTextContent
                                imgOnClick={openFromEvent}
                                richTextData={
                                    data.contentfulNews?.newsContent?.raw &&
                                    JSON.parse(
                                        data.contentfulNews?.newsContent?.raw
                                    )
                                }
                                references={
                                    data.contentfulNews?.newsContent
                                        ?.references as ContentfulAsset[]
                                }
                            />
                        </ArticleSection>
                    </ArticleContent>
                </ArticleWrapper>
            </SectionWrap>
        </GlobalLayout>
    )
}
