import { graphql } from 'gatsby'
import { NewsDetail } from '@/components/pages/News/Detail'

export default NewsDetail

export const query = graphql`
    query PageNews($slug: String!) {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
        }
        contentfulNews(slug: { eq: $slug }) {
            ...NewsInformation
            description
            newsContent {
                raw
                references {
                    ... on ContentfulAsset {
                        id
                        url
                        contentful_id
                    }
                    ... on ContentfulLives {
                        ...LivesInformation
                        contentful_id
                        internal {
                            type
                        }
                    }
                    ... on ContentfulNews {
                        ...NewsInformation
                        contentful_id
                        internal {
                            type
                        }
                    }
                    ... on ContentfulMusics {
                        ...MusicsInformation
                        contentful_id
                        internal {
                            type
                        }
                    }
                    ... on ContentfulVideos {
                        ...VideosInformation
                        contentful_id
                        internal {
                            type
                        }
                    }
                }
            }
        }
    }
`
